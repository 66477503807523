<!--
 * @Description: 业务建模通用弹窗
 * @Author: 梁平贤
 * @Date: 2020/6/28 11:35
 -->
<template>
  <en-dialog
    title="业务建模详情展示"
    :visible="dialogVisible"
    class="business-detail-dialog"
    width="1100px"
    append-to-body
    :fullscreen="fullscreen"
    @close="close"
  >
    <detail :notRouteParam="copiedBusinessPrams" @closeDialog="close" :exsitBusinessData="exsitBusinessData" :dataType="dialogType" :hideCollect="hideCollect"></detail>
  </en-dialog>
</template>

<script>
import { BusinessDataPageType } from "@/components/businessDetail/data/BusinessCommonHeader";

export default {
  name: "BusinessDialog",
  props: {
    hideCollect: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    dialogVisible: Boolean,
    // 业务建模参数
    businessParams: Object,
    // 类型
    dialogType: {
      type: Number,
      default: 1,
      validator(val) {
        // 1: 正常走弹窗参数,请求数据(businessParams)
        // 2: 外部传入业务建模数据
        return [1, 2].indexOf(val) !== -1;
      }
    },
    // 外部存在的业务建模数据,不需要请求接口,直接渲染
    exsitBusinessData: Object
  },
  data() {
    return {
    };
  },
  beforeCreate() {
    this.$options.components.detail = () => import("./index.vue");
  },
  computed: {
    copiedBusinessPrams() {
      const copy = JSON.parse(JSON.stringify(this.businessParams));
      copy.pageType = BusinessDataPageType.dialog;
      return copy;
    }
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$emit("update:dialogVisible", false);
    },
    // 提交成功的回调,外部可能需要知道
    submitEmit() {
      this.$emit("submitSuccess");
    }
  }
};
</script>

<style scoped lang="scss">
  .business-detail-dialog {
    & /deep/ .el-dialog__header {
      display: none;
    }
    .business-detail {
      min-height: 300px;
    }
    & /deep/ .el-dialog__body {
      padding: 0;
      & /deep/ .detail-content {
        min-height: 400px;
      }
    }
  }
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "business-detail-dialog",
      attrs: {
        title: "业务建模详情展示",
        visible: _vm.dialogVisible,
        width: "1100px",
        "append-to-body": "",
        fullscreen: _vm.fullscreen,
      },
      on: { close: _vm.close },
    },
    [
      _c("detail", {
        attrs: {
          notRouteParam: _vm.copiedBusinessPrams,
          exsitBusinessData: _vm.exsitBusinessData,
          dataType: _vm.dialogType,
          hideCollect: _vm.hideCollect,
        },
        on: { closeDialog: _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }